import React from "react";
import PageHead from "../pagesHead/PageHead";
import SubcribeSection from "../subscribe-section/SubcribeSection";
import InnerPageSection from "../inner-page-section/InnerPageSection";
import product1 from "../../images/product1.png";
import product2 from "../../images/product2.png";
import PartnerProduct from "../partner-products/PartnerProduct";

const imageDetails = [
  {
    heading: `AM Health`,
    para: `An enterprise solution for Doctors, Patient, Laboratory engagements for various engagement, pathological tests and their report with configurable options. It also provides video engagement, physical facility based test and home pick up based test.`,
    image: product1,
    alignment: `right`,
    buttonLabel: `Visit Now`,
    visitAddress: `https://binaryintuition.com/`,
    noBackgroundBlock: true,
  },
  {
    heading: `Binary Intuition`,
    para: `A social web platform that enables both virtual and in-person consultations with healthcare professionals while offering digital health record management. It allows users to host virtual events, seek second opinions from doctors, and securely share health records for a predetermined duration. Users can also manage their daily routines, while doctors have the flexibility to configure their availability and set consultation fees as per their preferences.`,
    image: product2,
    alignment: `right`,
    buttonLabel: `Visit Now`,
    visitAddress: `https://binaryintuition.com/`,
    noBackgroundBlock: true,
  },
];
const cardDetails = [
  {
    heading: `Maya Data Privacy`,
    para: `A comprehensive anonymization solution designed for handling large-scale data in files, databases, and applications, ensuring compliance with GDPR and other privacy regulations. This enables the use of anonymized data for purposes such as data analytics, research, AI processing, cross-organizational sharing, creating production copy for testing, secure information exchange within and between departments or organizations, outsourcing to contractors, and facilitating private-public data collaborations.`,
    btnLabel: `Visit Now`,
    btnLabelAddress: `https://mayadataprivacy.eu/`,
  },
  {
    heading: `TrueReach AI`,
    para: `Gen-AI solution to automatically create dashboards and reports.`,
    btnLabel: `Visit Now`,
    btnLabelAddress: `https://www.truereach.ai/`,
  },
];
const Product = () => {
  return (
    <div>
      <PageHead pageTitle={`Products`} />
      <InnerPageSection
        preYellowHeading={`Our `}
        yellowHeading={`PRODUCTS`}
        imagesDetails={imageDetails}
      />
      <PartnerProduct
        preYellowHeading={`Partner`}
        yellowHeading={`PRODUCTS`}
        para={`Areteminds Partnered with 2 Products based companies in India and Europe for their implementation`}
        cardDetails={cardDetails}
      />

      <SubcribeSection />
    </div>
  );
};

export default Product;
