import React from "react";

import InnerImagesDetails from "../../comman/inner-ImagesDetails/InnerImagesDetails";

const InnerPageSection = ({
  preYellowHeading,
  yellowHeading,
  postYellowHeading,
  para,
  imagesDetails,
}) => {
  
  return (
    <div>
      <section className="inner-page-section" data-aos="fade-up">
        <div className="container">
          <h2 className="sub-heading text-center">
            {preYellowHeading ? preYellowHeading : null}{" "}
            <span className="yellow">
              {yellowHeading ? yellowHeading : null}
            </span>{" "}
            {postYellowHeading ? postYellowHeading : null}
          </h2>
          <p className="sub-heading-p text-center mx-auto ">
            {para ? para : null}
          </p>
          {imagesDetails ? (
            <div>
              {imagesDetails.map((detail, i) => {
                return (
                  <InnerImagesDetails
                    key={i}
                    image={detail.image}
                    alignment={detail.alignment}
                    para={detail.para}
                    heading={detail.heading}
                    buttonLabel={detail.buttonLabel ? detail.buttonLabel : null}
                    vistAddress={
                      detail.visitAddress ? detail.visitAddress : null
                    }
                    noBackgroundBlock={
                      detail.noBackgroundBlock ? detail.noBackgroundBlock : null
                    }
                    badgesDetails={
                      detail.badgesDetails ? detail.badgesDetails : null
                    }
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default InnerPageSection;
