import React from "react";
import PageHead from "../pagesHead/PageHead";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import welcomeImage from "../../images/about.png";
import TechSlider from "../techSlider/TechSlider";
import Advantages from "../advantage/Advantages";
import solution from "../../images/solutions.png";
import advantages from "../../images/advantage.png";
import quality from "../../images/quality.png";
import innovation from "../../images/innovation.png";
import ServiceSection from "../service-section/ServiceSection";
import Testimonal from "../testimonal/Testimonal";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const details = [
  {
    icon: null,
    heading: null,
    para: "Reliable Tech Partner",
  },
  {
    icon: null,
    heading: null,
    para: "Deep Expertise",
  },
  {
    icon: null,
    heading: null,
    para: "Agile Teams",
  },

  {
    icon: null,
    heading: null,
    para: "Passion",
  },
];
const advantagedetails = [
  {
    icon: advantages,
    heading: `Reliable Tech Partner`,
    para: ` We’re reliable, agile, collaborative, and efficient—providing a fast-track startup-style experience. We work with an extensive network of partners and startups who collaborate with us to deliver solutions at speed and scale.`,
  },
  {
    icon: innovation,
    heading: `Continuous Innovations`,
    para: `We move clients into a rewarding digital future through our AreteMinds development framework evolved and improving over the time through continuous innovations. We reduce the development time and cost by 30%.`,
  },
  {
    icon: solution,
    heading: `Tailored Solutions`,
    para: `We deliver tailored made solutions as per your specifications. you are
    involved in the development process from start to finish, ensuring the
    end result aligns with your company needs.
    `,
  },

  {
    icon: quality,
    heading: `Affordable Quality Services`,
    para: `We have tools and framework available which brings down the cost of
    development and maintenance. We pass these benefits to our clients.`,
  },
];
const cardDetails = [
  {
    heading: `Our VISION`,
    para: `Create sustainable propositions for clients, people and communities.`,
    color: `#fff`,
    nohover: true,
  },
  {
    heading: `Our MISSION`,
    para: `To solve our clients’ toughest challenges by providing unmatched services in digital, technology and staffing.`,
    nohover: true,
  },
  {
    heading: `Our VALUES`,
    para: `Create sustainable propositions for clients, people and communities.`,
    nohover: true,
  },
];
const About = () => {
  return (
    <div>
      <div>
        <PageHead pageTitle={"About Us"} />
      </div>
      <div>
        <WelcomeSection
          preYellowHeading={`Driving`}
          yellowHeading={`DIGITAL`}
          postYellowHeading={`transformation
at the pace and scale`}
          para={`Arete is an ancient Greek word meaning excellence or virtue. We stand for excellence in delivery, quality and innovation. AreteMinds is a Mindful IT Company, enables digitalctransformation for enterprises and technology providers by bringing skills such as technology experts, functional experts and cloud experts to meet your IT and Business needs. We do this by leveraging a spectrum of disruptive technologies such as: cloud, digital and automation.`}
          badgesDetails={details}
          sectionImage={welcomeImage}
          nobutton={true}
        />
      </div>
      <div>
        <TechSlider
          preYellowHeadin={`We have`}
          yellowHeading={`EXPERTS`}
          postYellowHeading={`in trending
technologies`}
        />
      </div>
      <div>
        <Advantages
          badgesDetails={advantagedetails}
          preYellowHeading={`Our`}
          yellowHeading={`ADVANTAGES`}
        />
      </div>
      <div className="we-section">
        <ServiceSection
          preYellowHeading={`Who`}
          yellowHeading={`WE`}
          postYellowHeading={`are`}
          cardDetails={cardDetails}
          classNamegrid={`col-lg-4 col-md-12 col-12`}
        />
      </div>
      <div>
        <Testimonal />
      </div>
      <div>
        <SubcribeSection />
      </div>
    </div>
  );
};

export default About;
