import React from "react";
import project1 from "../../images/project-1.png";
import project2 from "../../images/project-2.png";
import project3 from "../../images/project-3.png";
import project4 from "../../images/project-4.png";
import project5 from "../../images/project-5.png";
import project6 from "../../images/project-6.png";
import { Link } from "react-router-dom";

const InnerPageSection = () => {
  return (
    <div>
      <section className="inner-page-section" data-aos="fade-up">
        <div className="container">
          <div className="row mt-5" data-aos="fade-up">
            <div className="col-md-6 col-sm-12 col-12">
              <Link to="/foododering" className="project-box">
                <img src={project1} className="img-fluid w-100" alt="" />
                <div>
                  <h4>No Touch Food Ordering</h4>
                  <p>
                    In current pandemic, the client enabled many restaurants to
                    go touchless ordering of food. We have developed solutions
                    that ensure smooth food ordering and ensure no business
                    disruption
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <Link to="/healthcare" className="project-box">
                <img src={project2} className="img-fluid w-100" alt="" />
                <div>
                  <h4>Healthcare Made Easy</h4>
                  <p>
                    An innovative mobile medical service that allows you to take
                    control of your health with only a few clicks. Order lab
                    tests, flu shots, vaccines and more using mobile & web app.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <Link to="/working" className="project-box">
                <img src={project3} className="img-fluid w-100" alt="" />
                <div>
                  <h4>App for working professionals</h4>
                  <p>
                    Trusted, safe, anonymous social network for working
                    professionals. It lets you have anonymous conversations with
                    your colleagues. Ask for career tips, salary, feedback on
                    executives.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <Link to="/learning" className="project-box">
                <img src={project4} className="img-fluid w-100" alt="" />
                <div>
                  <h4>Learn from peers</h4>
                  <p>
                    For mid and high-school students to solve short “challenge”
                    problems, If the problem s/he is unable to solve it, the
                    student posts it online to seek inputs/ help from
                    class-mates, initially within a chosen sub-group.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <Link to="/logistics" className="project-box">
                <img src={project5} className="img-fluid w-100" alt="" />
                <div>
                  <h4>Logistics Made Simpler & Easier With Technology</h4>
                  <p>
                    Client is Redefining Logistics by integrating technology and
                    services. We built technology platform that helps businesses
                    to optimize the complete supply chain along with providing
                    great customer experience and better profit margins.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <Link to="/donation" className="project-box">
                <img src={project6} className="img-fluid w-100" alt="" />
                <div>
                  <h4>A Digital Donation Platform</h4>
                  <p>
                    Developed a digital platform, which aims to connect both
                    nonprofit organizations and donors, through understandable,
                    clearly defined small items for causes. We redefined the
                    experience of giving, both for people and organizations.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerPageSection;
