import React from "react";
import BadgesDetails from "../badgesDetails/BadgesDetails";

const InnerImagesDetails = ({
  image,
  heading,
  para,
  alignment,
  buttonLabel,
  vistAddress,
  noBackgroundBlock,
  badgesDetails,
}) => {
  return (
    <div>
      {alignment === "right" ? (
        <div>
          <div
            className="row align-items-center mt-lg-5 mt-2"
            data-aos="fade-right"
          >
            <div className="col-md-12 col-lg-6">
              <div className="inner-page-content">
                <h2 style={{display:"flex",justifyContent:"center",
                alignItems:"center",textAlign:"center"
              }}>{heading}</h2>
                <p>{para}</p>

                {badgesDetails ? (
                  <div>
                    {badgesDetails.map((detail, i) => {
                      return (
                        <div className="welcome-box">
                          <div className="d-flex align-items-center">
                            <BadgesDetails
                              key={i}
                              icon={detail.icon ? detail.icon : null}
                              heading={detail.heading ? detail.heading : null}
                              details={detail.para ? detail.para : null}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {buttonLabel ? (
                  <div class=" mt-2">
                    <a class="btn btn-black" target="_blank" href={vistAddress}>
                      {buttonLabel}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <figure
                className={
                  noBackgroundBlock
                    ? `text-center d-lg-block d-none`
                    : `inner-page-image-two yellow-b d-lg-block d-none`
                }
              >
                <img src={image} className="img-fluid" alt="" />
              </figure>
            </div>
          </div>
        </div>
      ) : null}
      {/* {alignment === "left" ? (
        <div>
          <div
            className="row align-items-center mt-lg-5 mt-2"
            data-aos="fade-left"
          >
            <div className="col-md-12 col-lg-6">
              <figure
                className={
                  noBackgroundBlock
                    ? `text-center d-lg-block d-none`
                    : `inner-page-image-two yellow-b d-lg-block d-none`
                }
              >
                <img src={image} className="img-fluid" alt="" />
              </figure>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="inner-page-content">
                <h2>{heading}</h2>
                <p>{para}</p>
                {badgesDetails ? (
                  <div>
                    {badgesDetails.map((detail, i) => {
                      return (
                        <div className="welcome-box">
                          <div className="d-flex align-items-center">
                            <BadgesDetails
                              key={i}
                              icon={detail.icon ? detail.icon : null}
                              heading={detail.heading ? detail.heading : null}
                              details={detail.para ? detail.para : null}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {buttonLabel ? (
                  <div class=" mt-2">
                    <a class="btn btn-black" target="_blank" href={vistAddress}>
                      {buttonLabel}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default InnerImagesDetails;
