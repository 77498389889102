import React from "react";
// import brand1 from "../../images/bajaj.png";
import brand1 from "../../images/Gusno.png"
import brand2 from "../../images/benison.png";
import brand3 from "../../images/dost.png";
import brand4 from "../../images/Tata_Motors_logo.png";
// import brand5 from "../../images/large_citizencargo.png";
import brand5 from "../../images/factspannnew.png";
import brand6 from "../../images/snapdeal.png";
import brand7 from "../../images/ripplr.png";

import SliderBar from "../../comman/slider/SliderBar";
const generateTechImages = (count) => {
const BrandImage = [
  { image: brand1 },
  { image: brand2 },
  { image: brand3 },
  { image: brand4 },
  { image: brand5 },
  { image: brand6 },
  { image: brand7 },
 
];
const recursiveTechImages = [];
for (let i = 0; i < count; i++) {
  recursiveTechImages.push(...BrandImage);
}

return recursiveTechImages;
};


const BrandSlider = () => {
  const BrandImage = generateTechImages(10);
  return (
    <div>
      <section className="brand-slider">
        <h2 className="sub-heading text-center">
          See our <span className="yellow">Trusted</span> Brands
        </h2>
        <div className="slider container-fluid">
          <div className=" slide-track  d-flex justify-content-between align-items-center">
            {BrandImage.map((image) => {
              return (
                <div className="animate-div slide ">
                  <SliderBar images={image.image} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrandSlider;
