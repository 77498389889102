import React from "react";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import sectionimage from "../../images/project-1.png";
import WeSection from "../we-section/WeSection";
import PageHead from "../pagesHead/PageHead";

const cardDetails = [
  {
    heading: "The Challenge",
    para: `The client had distributed teams across the globe and was rapidly expanding through acquisitions in additional locations. These locations were not integrated seamlessly into one infrastructure. This severely impacted the project implementation, development, and software quality.

    `,
    color: "#fff",
    nohover: true,
  },
  {
    heading: `The Solution`,
    para: `We have developed a new contactless in-store ordering technology for the food and beverage industries. Restaurant guests can scan a QR code to get access to the full menu, place their order, receive in-the-moment updates, and pay, all from their own devices. This technology not only keeps staff and guests safe, but it also improves efficiency, and reduces overhead costs. The safety of staff and customers is paramount, so we designed this solution to minimise staff and customer interactions by digitising ~70% of the customer experience which not only slows the spread of the virus but also created a unique and enjoyable dining experience.

        `,
    color: "#fff",
    nohover: true,
  },
  {
    heading: `The Outcome`,
    para: `Reduced direct contact time with staff by ~85%. Reduce overheads on staffing - Due to the reduction in staff needed to manage tables and handle payments we were able to showcase a potential reduction in staffing costs of 25-40% when using our solution. Reduced pressure on floor staff, requiring less staff per table but also allowing them time to adhere appropriately to all the new protocols and producers in place due to COVID19. Improved table turnaround time ~10% meaning they can have more tables, and make more money. Helping build back some of the lost revenue due to reduced tables for social distancing. Reducing the number of things customers touch , slowing the spread of the virus Ensuring compliance with changing government guidance and assisting with implementation on the ground.

        `,
    color: "#fff",
    nohover: true,
  },
];
const FoodOrdering = () => {
  return (
    <div>
      <PageHead pageTitle={`No Touch Food Ordering`} />
      <WelcomeSection
        postYellowHeading={`No Touch Food Ordering`}
        sectionImage={sectionimage}
        nobutton={true}
        para={`In the current pandemic, there is a need for low contact service delivery to contain the spread of virus. It’s no surprise that consumers now clearly prefer low and zero contact interactions to ensure their safety. Our client found that there is a decline in sales of restaurants due to people's fear for Covid-19. The client wanted to solve the problem of declining sales and to restore the customer sentiments for dine out or pick-up. They came to us to find solutions for the restaurant business. We have developed solutions that ensure smooth food ordering and ensure no business disruption. The client enabled many restaurants to go touchless ordering of food with our solution.`}
      />
      <WeSection cardDetails={cardDetails} />
    </div>
  );
};

export default FoodOrdering;
