import React from "react";
import "../../css/custom.css";
import "../../css/global.css";
import "../../css/aos.css";
import logo from "../../images/logo.png";
import callFooter from "../../images/callfooter.png";
import mailFooter from "../../images/mail-footer.png";
import locationFooter from "../../images/location-footer.png";
import fb from "../../images/fb.png";
import li from "../../images/li.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row mx-0">
            <div className="col-lg-5 col-12">
              <Link to="home.html">
                <img src={logo} className="img-fluid" alt="" />
              </Link>
              <p className="footer-dec">
                Our Digital Services drives true digital transformation with the
                power of perspective. From Idea to reality we help you put
                digital at the core for sustainable and profitable business
                growth.
              </p>
            </div>
            <div className="col-lg-7 col-12">
              <div className="row mx-0">
                <div className="col-md-4 col-sm-6 col-6 px-0">
                  <h4>Quick Links</h4>

                  <p>
                    <Link to="/about">About Us</Link>
                  </p>
                  <p>
                    <Link to="/portfolio">Portfoilo</Link>
                  </p>
                  <p>
                    <Link to="/team">Teams</Link>
                  </p>
                  <p>
                    <Link to="/contactus">Contact</Link>
                  </p>
                </div>
                <div className="col-md-4 col-sm-6 col-6 px-0">
                  <h4>Services</h4>

                  <p>
                    <Link to="/digitaloffering">Digital Services</Link>
                  </p>
                  <p>
                    <Link to="/cloud">Data & AI</Link>
                  </p>
                  <p>
                    <Link to="/staffagumentation">Staff Augmentation</Link>
                  </p>
                  <p>
                    <Link to="/testing">Testing</Link>
                  </p>
                </div>
                <div className="col-md-4 col-sm-6 col-6 px-0">
                  <h4>Contact Info</h4>
                  <p className="d-flex align-items-center">
                    <img src={callFooter} className="img-fluid" alt="" />
                    <Link className="ps-2" to="tel:+91 9663205304">
                      +91 9663205304
                    </Link>
                  </p>
                  <p className="d-flex align-items-center">
                    <img src={mailFooter} className="img-fluid" alt="" />
                    <Link className="ps-2" to="mailto:sales@areteminds.com">
                      sales@areteminds.com
                    </Link>
                  </p>
                  <p className="d-flex align-items-start">
                    <img src={locationFooter} className="img-fluid" alt="" />
                    <Link
                      to="https://maps.app.goo.gl/AFDAbqPLttmHPYGE9"
                      target="_blank"
                      className="ps-2"
                    >
                      4th Cross, Ramanjaneya Layout
                      <br />
                      Marathahalli, Bangalore India
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="footer-bottom">
            <div className="d-md-flex justify-content-between align-items-center">
              <p className="mb-0">Copyright © 2024 | Powered by AreteMinds</p>
              <div className="d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
                <Link to="#" target="_blank">
                  <img src={fb} className="img-fluid me-2" alt="" />
                </Link>
                <Link
                  to="https://in.linkedin.com/company/aretemindstechnologiesprivatelimited"
                  target="_blank"
                >
                  <img src={li} className="img-fluid " alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
