import React from "react";
import a1 from "../../images/a1.png";
import a2 from "../../images/a2.png";
import a3 from "../../images/a3.png";
import approachImage from "../../images/approach homepage.png";
import BadgesDetails from "../../comman/badgesDetails/BadgesDetails";
import ImageFrame from "../../comman/ImageFrame/ImageFrame";

const approachDetails = [
  {
    icon: a1,
    heading: "Discover & Explore",
    details: `Use design thinking to understand customer journeys,
    identify gaps, and unmet needs.`,
  },
  {
    icon: a2,
    heading: "Design & Execution",
    details: `Get the right influencers and stakeholders to create
    instant alignment to ensure trajectory.`,
  },
  {
    icon: a3,
    heading: "Deployment & Improvement",
    details: `Setup process to deliver software to a production
    environment with speed, safety, and reliability.`,
  },
];

const ApproachSection = () => {
  return (
    <div>
      <section className="approach-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <h2 className="sub-heading">
                Our <span className="yellow">Approach</span>
              </h2>
              <div className="approach-box">
                {approachDetails.map((approach, i) => {
                  return (
                    <div className="m-3">
                      <BadgesDetails
                        key={i}
                        icon={approach.icon}
                        heading={approach.heading}
                        details={approach.details}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-12">
              <figure className="Approach-img d-lg-block d-none">
                <ImageFrame image={approachImage} />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ApproachSection;
