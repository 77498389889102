import React from "react";
import PageHead from "../pagesHead/PageHead";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import sectionimage from "../../images/staff-main.png";
import InnerPageSection from "../inner-page-section/InnerPageSection";
import image1 from "../../images/Talent-On-demand.png";
import image2 from "../../images/remote-developer.png";
import image3 from "../../images/dedicated-teams.png";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const details = [
  {
    para: `Fulfill urgent technical expertise like Java/SpringBoot/NodeJs/ ReactJs, ReactNative and DevOps.`,
  },
  {
    para: `Work as an extended team in case you are running out of bandwidth or specific skill set.
  `,
  },
  {
    para: `Meet your short term to medium term resource requirements with a 5-7 days availability.
  `,
  },
  {
    para: `Plan your cloud strategy, choose the right public cloud, assist you with architecting, transitioning, migrating, and managing your cloud solutions.
    `,
  },
  {
    para: `Building great products by blending our skills with new-age technologies.
    `,
  },
];
const imagedetails = [
  {
    heading: `Talent On-demand`,
    para: `The pandemic Covid-19 has increased the demand for IT and DevOps Skills across the globe. As the specialized skills gap and talent shortage continue to expand, AreteMinds readies its workforce to provide temporary, industry-specific support to companies that cannot afford to wait for talent.`,
    image: image1,
    alignment: "right",
  },
  {
    heading: `Remote Developer Services`,
    para: `AreteMinds gives your business a brilliant opportunity to build an efficient remote tech development team and with a price advantage. You get to deploy the best-skilled tech manpower in the world who will work exclusively for you for a tenure of your choice. They are fully supported by the infrastructure of our development centres in India.`,
    image: image2,
    alignment: "right",
  },
  {
    heading: `Dedicated Teams`,
    para: `We offer dedicated teams with all skills required to execute and implement project successfully. We take away training, managing team and retaining team work from our customers so that they can focus on their core business. Our selection process includes attitude, aptitude, technical knowledge and soft skills.`,
    image: image3,
    alignment: "right",
  },
];
const StaffAgumentation = () => {
  return (
    <div>
      <PageHead pageTitle={`Staff Augmentation`} />
      <WelcomeSection
        preYellowHeading={`Looking for experienced`}
        yellowHeading={`Developers`}
        postYellowHeading={`?`}
        para={`The whole industries facing common problem that selected candidates are declining job offer close to the joining date and joining some other company.We think that by offering our expertise we can add value to your engineering team. Be it temporary/contract hiring or building an entire off-shore development center for your business, AreteMinds has the best solution and resources to offer you.`}
        sectionImage={sectionimage}
        postPara={`We can enable you to:`}
        badgesDetails={details}
        nobutton={true}
      />
      <InnerPageSection
        preYellowHeading={`Explore Our`}
        yellowHeading={`STAFF AUGMENTATION`}
        postYellowHeading={`Services`}
        para={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.`}
        imagesDetails={imagedetails}
      />
      <SubcribeSection />
    </div>
  );
};

export default StaffAgumentation;
