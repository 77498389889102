import React from "react";
import { Carousel } from "antd";
const contentStyle = {
  height: "160px",
  color: "black",
  lineHeight: "160px",
  textAlign: "center",
  background: "#fff",
};
const Slider = ({ customerDetails }) => (
  <Carousel autoplay>
    {customerDetails ? customerDetails.map((details, i) => {
      return <div style={contentStyle} key={i}>

        <div class="testimonial-div" >

          <p>{details.para}</p>
          <h3>{details.heading}</h3>
          <small>{details.founderName}</small>
        </div>


      </div>

    }) : null}


  </Carousel>
);
export default Slider;
