import React from "react";
import ImageFrame from "../../comman/ImageFrame/ImageFrame";
import image from "../../images/website-hosting-concept-with-hands-holding-tablet 1.png";
import quote1 from "../../images/quote.png";
import Slider from "../../comman/carousal/Slider";

const customerDetails = [
  {
    para: `We are pleased to have Areteminds as our technology partner.
  They not only showed their commitment and hard work in our
  project, but also helped us take care of some points that
  IndividualIntervalsExample got missed by us and did not belong to their scope. It is a
  great help if the objective is in making the service
  successful, instead of just completing the project. Thanks
  and please keep on the same service and accountability!`,
    heading: `Subodh Patil`,
    founderName: `Founder, Brisa Technologies`,
  },
  {
    para: `We are thrilled to acknowledge Areteminds as our trusted technology partner.
     Their unwavering commitment and diligence in our project have surpassed our expectations.
      Beyond the defined scope, they demonstrated a keen eye for detail by addressing points that we overlooked,
       contributing significantly to the project's success. It's truly valuable to have a partner who is dedicated 
       not only to project completion but also to the overall success of our service. We extend our sincere thanks and 
       encourage Areteminds to maintain their high standards of service and accountability.
     Looking forward to continued success together`,
    heading: `LMS`,
    founderName: `Founder, Learning management Technologies`,
  },
  {
    para:`We are delighted to express our gratitude to Areteminds for their outstanding dedication as our technology partner.
     Their commitment and hard work throughout the project have been commendable. Not only did they fulfill their responsibilities 
     diligently,but they also went above and beyond to address certain aspects that were overlooked on our end and were not 
     originally part of their scope. Their proactive approach towards ensuring the overall success of the service, rather than 
     merely completing the project, has been invaluable to us. We appreciate their exceptional service and accountability, and we
      look forward to continuing this successful partnership. Thank you, and please continue delivering excellence!`,
    heading: `Snapdeal`,
    founderName: `Founder, Snapdeal Online Market`,
  },
];

const Testimonal = () => {
  return (
    <div>
      <section className="testimonial-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <figure className="testimonial-img d-none d-lg-block">
                <ImageFrame image={image} />
              </figure>
            </div>

            <div className="col-lg-6 col-md-12 col-12">
              <h2 className="sub-heading">
                What our <span>client</span> says
              </h2>
              <img src={quote1} className="img-fluid mt-3 mb-2" alt="" />
              <div id="testimonialSlider">
                <Slider customerDetails={customerDetails} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonal;
