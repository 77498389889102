import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../components/home/Home";
import About from "../components/about/About";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Solution from "../components/solution/Solution";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "../components/contact/Contact";
import DigitalOffering from "../components/digital-offering/DigitalOffering";
import Cloud from "../components/cloudEngineering/Cloud";
import Testing from "../components/testing/Testing";
import StaffAgumentation from "../components/staff-agumentation/StaffAgumentation";
import Product from "../components/product/Product";
import FoodOrdering from "../components/food-ordering/FoodOrdering";
import HealthCare from "../components/healthcare/HealthCare";
import Working from "../components/working/Working";
import Learning from "../components/learning/Learning";
import Logistic from "../components/logistic/Logistic";
import Donation from "../components/donation/Donation";
import Industry from "../components/industry/Industry";
import Team from "../components/team/Team";
import { useLocation, useNavigationType, Outlet } from "react-router-dom";

const AppRouter = () => {
  const [direction, setDirection] = useState("");

  function ScrollRestoration() {
    const location = useLocation();

    setDirection(location.pathname);
    const navigationType = useNavigationType();

    useEffect(() => {
      // Check if this is a "PUSH" navigation and scroll to top
      if (navigationType === "PUSH") {
        window.scrollTo(0, 0);
      }
    }, [location, navigationType]);

    return <Outlet />;
  }

  return (
    <Router>
      <Header active={direction} />
      <Routes>
        <Route element={<ScrollRestoration />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/solution" element={<Solution />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/digitaloffering" element={<DigitalOffering />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/staffagumentation" element={<StaffAgumentation />} />
          <Route path="/product" element={<Product />} />
          <Route path="/foododering" element={<FoodOrdering />} />
          <Route path="/healthcare" element={<HealthCare />} />
          <Route path="/working" element={<Working />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/logistics" element={<Logistic />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/industry" element={<Industry />} />
          <Route path="/team" element={<Team />} />
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
