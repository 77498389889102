import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import closeIcon from "../../images/close.svg";
import logo from "../../images/logo.png";

const Header = ({ active }) => {
  const [scroll, setScroll] = useState(false);
  const [click, setClick] = useState(false);

  useEffect(() => {
    const scrolled = () => {
      window.addEventListener("scroll", (e) => {
        if (window.scrollY === 0) {
          setScroll(false);
        } else {
          setScroll(true);
        }
      });
    };

    scrolled();
  }, []);

  const handleOnCLick = () => {
    setClick(!click);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg fixed-top ${
          scroll ? `bg-white` : null
        } `}
        style={{ color: `${active !== "/" ? "white" : "black"}` }}
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleOnCLick}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse justify-content-end ${
              click ? "show" : ""
            }`}
            id="navbarSupportedContent"
          > 
            <ul className="navbar-nav align-items-lg-center">
              <li className="nav-item mx-0">
                <Link
                  className={click ? "show-icon" : "close-icon"}
                  onClick={handleOnCLick}
                >
                  <img src={closeIcon} className="img-fluid" alt="" />
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={`${`nav-link`} ${
                    active === `/` ? `active` : null
                  } ${`mx-0`}`}
                  to="/" 
                  // onClick={() => setClick(false)
                  // }
                  onClick={() => {
                    setClick(false);
                    window.location.replace("/");
                  }}
                >
                  Home
                </Link>
           
              </li>
              <li className="nav-item">
                <Link
                  className={`${`nav-link`} ${
                    active === "/about" ? "active" : null
                  } ${`mx-0`}`}
                  to="/about"
                  // onClick={() => setClick(false)}
                  onClick={() => {
                    setClick(false);
                    window.location.replace("/about");
                  }}
                >
                  About Us
                </Link>
              </li>

              <NavDropdown
                title={<span>Services</span>}
                id="basic-nav-dropdown "
                data-bs-theme="dark"
                style={{
                  color:
                    active === "/digitaloffering" ||
                    active === "/cloud" ||
                    active === "/testing" ||
                    active === "/staffagumentation"
                      ? "#e07243"
                      : null,
                }}
              >
                <NavDropdown.Item   onClick={() => {
                      window.location.replace("digitaloffering")
                    }}>                 
                    Digital Offerings
              
                </NavDropdown.Item>

                 <NavDropdown.Item 
                 onClick={() => {
                  window.location.replace("cloud")
                }}
                 >Data And AI
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => {
                    window.location.replace("testing")
                  }}
                  className="am_header_navbar_item">
                  Testing
              
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    window.location.replace("staffagumentation")
                  }}

                  className="am_header_navbar_item">
                  Staff Augmentation
                 
                </NavDropdown.Item>
              </NavDropdown>
              <li className="nav-item ">
                <Link
                  className={`${`nav-link`} ${
                    active === "/solution" ? "active" : null
                  }`}
                  to="/solution"
                  onClick={() => setClick(false)}
                >
                  Solutions
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className={`${`nav-link`} ${
                    active === "/portfolio" ||
                    active === "/foododering" ||
                    active === "/healthcare" ||
                    active === "/working" ||
                    active === "/learning" ||
                    active === "/logistics" ||
                    active === "/donation"
                      ? "active"
                      : null
                  }`}
                  to="/portfolio"
                  // onClick={() => setClick(false)}
                  onClick={() => {
                    setClick(false);
                    window.location.replace("/portfolio");
                  }}
                  
                >
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${`nav-link`} ${
                    active === "/contactus" ? "active" : null
                  }`}
                  to="/contactus"
                  onClick={() => setClick(false)}
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className="nav-link quote-btn"
                  to="/contactus"
                  // onClick={() => setClick(false)}
                  onClick={() => {
                    setClick(false);
                    window.location.replace("/contactus");
                  }}
                >
                  Get a Quote
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
