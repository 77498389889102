import React from "react";
import styled from "styled-components";
import Badges from "../badges/Badges";
import { Link } from "react-router-dom";

const Card = ({
  backgroundColor,
  icon,
  heading,
  para,
  button,
  btnLabel,
  size,
  img,
  btnLabelAddress,
  address,
  wbox,
  nohover,
}) => {
  // width: ${width ? width : "100px"};
  // height: ${height ? height : "100px"};
  const CardBox = styled.div`
    border-radius: 30px;
    background-color: ${backgroundColor ? backgroundColor : "#fff8ee"};
    padding: 20px;
    position: relative;
    z-index: 0;

    overflow: hidden;
    cursor: pointer;
    display: block;
    color: black;
  `;
  const Heading = styled.h4`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 10px;
  `;
  const Icon = styled.figure`
    background-color: #ffb600;
    border-radius: 20px;
    width: 65px;
    height: 65px;
    text-align: center;
    padding: 16px;
  `;
  const Para = styled.p`
    font-size: 14px;
    text-align: start;

    padding-bottom: 40px;
  `;
  const UnderLine = styled.hr`
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
  `;
  const Container = styled.div`
  margin-top:10px;
  display:flex
  align-items: center!important;
  justify-content: space-between!important;

  `;
  const ImageContainer = styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  `;
  return (
    <div>
      {size ? (
        <div>
          {size === "large" ? (
            <Link to={address}>
              <CardBox className="white-box">
                <Heading>{heading}</Heading>
                <Para>{para}</Para>
                {button ? (
                  <div>
                    <Link className="btn btn-black" to={btnLabelAddress}>
                      {btnLabel}
                    </Link>
                  </div>
                ) : (
                  <div>
                    <UnderLine />
                    <Container className="d-flex justify-content-between align-items-center">
                      {icon ? <Badges icon={icon} /> : null}
                      <ImageContainer src={img} alt="" />
                    </Container>
                  </div>
                )}
              </CardBox>
            </Link>
          ) : (
            <Link to={address}>
              <CardBox className={nohover ? "card" : "card-box"}>
                {icon ? <Badges icon={icon} /> : null}
                {heading ? <Heading>{heading}</Heading> : null}
                {para ? <Para>{para}</Para> : null}
              </CardBox>
            </Link>
          )}
        </div>
      ) : null}
      {wbox ? (
        <div>
          <div className="w-box">
            <h2>{heading}</h2>
            <p>{para}</p>
            <div className=" mt-2">
              <a
                className="btn btn-black"
                target="_blank"
                href={btnLabelAddress}
              >
                {btnLabel}
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Card;
