import React from "react";
import "../../css/custom.css";
import "../../css/global.css";
import "../../css/aos.css";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import cloudComputing from "../../images/Hompage1.png";
import ImageFrame from "../../comman/ImageFrame/ImageFrame";

const BannerInner = () => {
  return (
    <div>
      <section className="banner-inner">
        <div className="container">
          <div className="row mx-0 d-lg-block d-none ">
            <div className="col-xl-6 col-lg-5 col-md-5 banner-bg"></div>
            <div className="col-xl-6 col-lg-7 col-md-7"></div>
          </div>
          <div className="row mx-0 banner-content">
            <div className="col-lg-5 col-md-12 col-12 ">
              <h1>
                Unlock Growth Opportunities with Data, <br />
                <span className="yellow">AI-Powered</span> and Digital Solutions
              </h1>
              <p>
                We are your trusted technology partner. We are known for our
                agile entrepreneurship, client centric and for the value we
                generate.
              </p>

              <Link className="btn quote-btn" to="contactus">
                Get a Quote
              </Link>
            </div>
            <div className="col-lg-7 col-md-12 banner-img col-12">
              {/* <ImageFrame image={cloudComputing} /> */}
              <img src={cloudComputing} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BannerInner;
