import React from "react";
import Card from "../../comman/card/Card";

const ServiceSection = ({
  preYellowHeading,
  yellowHeading,
  postYellowHeading,
  para,
  cardDetails,
  classNamegrid,
}) => {
  return (
    <div>
      <section
        className="services-section"
        // style={{ background: "", margin: "5rem 0", padding: "4rem 0" }}
      >
        <div className="container">
          <h2 className="sub-heading text-center">
            {preYellowHeading}
            <span className="yellow"> {yellowHeading}</span> {postYellowHeading}
          </h2>
          {para ? (
            <p className="sub-heading-p text-center mx-auto">{para}</p>
          ) : null}

          <div className="row mt-5">
            {cardDetails.map((details, i) => {
              return (
                <div key={i} className={classNamegrid}>
                  <Card
                    heading={details.heading}
                    para={details.para}
                    icon={details.icon}
                    address={details.address}
                    backgroundColor={
                      details.backgroundColor ? details.backgroundColor : `#fff`
                    }
                    size={`small`}
                    nohover={details.nohover}
                  />
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceSection;
