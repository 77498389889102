import React from "react";
import { Link } from "react-router-dom";
import ImageFrame from "../../comman/ImageFrame/ImageFrame";
import BadgesDetails from "../../comman/badgesDetails/BadgesDetails";

const WelcomeSection = ({
  preYellowHeading,
  yellowHeading,
  postYellowHeading,
  para,
  sectionImage,
  badgesDetails,
  nobutton,
  postPara,
}) => {
  return (
    <div>
      <section className="welcome-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12 ">
              <figure className="welcome-img d-lg-block d-none">
                <ImageFrame image={sectionImage} />
              </figure>
            </div>

            <div className="col-lg-6 col-md-12 col-12 ">
              <h2 className="sub-heading">
                {preYellowHeading}{" "}
                <span className="yellow">{yellowHeading}</span>{" "}
                {postYellowHeading}
              </h2>
              <p>{para}</p>
              {postPara ? <p>{postPara ? postPara : null}</p> : null}
              {badgesDetails ? (
                <div>
                  {badgesDetails.map((detail, i) => {
                    return (
                      <div className="welcome-box">
                        <div className="d-flex align-items-center">
                          <BadgesDetails
                            key={i}
                            icon={detail.icon ? detail.icon : null}
                            heading={detail.heading}
                            details={detail.para}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {nobutton ? null : (
                <Link className="btn quote-btn" to="/about">
                  Read More
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WelcomeSection;
