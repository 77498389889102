import React from "react";
import Badges from "../badges/Badges";
import dot from "../../images/orange-dot.png";

const BadgesDetails = ({ icon, heading, details }) => {
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="order-1">
          {icon ? (
            <figure>
              <Badges icon={icon} />
            </figure>
          ) : (
            <img
              src={dot}
              alt=""
              className="img-fluid"
              style={{ marginRight: "10px" }}
            />
          )}
        </div>
        <div className="order-2">
          {heading ? <h4>{heading}</h4> : null}
          <p style={{display:"",justifyContent:"left",alignItems:"left",textAlign:"left"}}>{details}</p>
        </div>
      </div>
    </div>
  );
};

export default BadgesDetails;
