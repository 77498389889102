import React from "react";
import PageHead from "../pagesHead/PageHead";
import InnerPageSection from "../inner-page2-section/InnerPageSection";
import TechSlider from "../techSlider/TechSlider";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const Portfolio = () => {
  return (
    <div>
      <PageHead pageTitle={`Projects`} />
      <InnerPageSection />
      <TechSlider
        preYellowHeadin={`We have`}
        yellowHeading={`EXPERTS`}
        postYellowHeading={`in trending
technologies`}
      />
      <SubcribeSection/>
    </div>
  );
};

export default Portfolio;
