import { useEffect } from "react";
import "./App.css";
import AppRouter from "./Routes/AppRouter";

function App() {
  useEffect(() => {
    window.scroll(() => {
      var scroll = window.scrollY;
      if (scroll >= 50) {
        document.getElementsByClassName("nav").addClass("bg-white");
      } else {
        document.getElementsByClassName("nav").removeClass("bg-white");
      }
    });
  });

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
