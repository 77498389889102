import React from "react";

const PageHead = ({ pageTitle }) => {
  return (
    <div>
      {" "}
      <section className="banner-section">
        <div class="page-header">
          <h2 style={{display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center"}}>{pageTitle}</h2>
        </div>
      </section>
    </div>
  );
};

export default PageHead;
