import React from "react";
import Card from "../../comman/card/Card";
import i1 from "../../images/i1.png";
import i2 from "../../images/i2.png";
import i3 from "../../images/i3.png";
import arrow from "../../images/Arrow 1.png";

const whiteCardDetails = [
  {
    heading: "BFSI",
    para: `Our services use disruptive technologies of AI, App
  Modernization, CRM, Analytics and Cloud to help financial
  institutions adopt modern banking software and solutions to
  achieve their goals. We specialises in area such as lending
  journey, KYC, customer onboarding and digital payments`,
    icon: i1,
    address: "/industry",
  },
  {
    heading: "Healthcare",
    para: `Our services help healthcare companies to provide best patient
    experience. We help such companies with patient engagement
    platform, Tele- consultation, Remote Monitoring, Tele-medicine
    and patient care management technologies.`,
    icon: i2,
    address: "/industry",
  },
  {
    heading: "Education",
    para: `We offers services and solutions addressing the various
    requirements of education e - publishing Industry. Our services
    enable companies design and manage and improve a number of
    systems.`,
    icon: i3,
    address: "/industry",
  },
];

const IndustrySection = () => {
  return (
    <div>
      <section className="industry-section ">
        <div className="container">
          <h2 className="sub-heading text-center">
            Focus <span className="yellow">Industries</span>
          </h2>
          <p className="sub-heading-p text-center mx-auto">
            We leverage our expertise across below industries to drive
            technology-led business breakthroughs.
          </p>

          <div className="row mt-5">
            {whiteCardDetails.map((details, i) => {
              return (
                <div key={i} className="col-md-12 col-lg-4 col-sm-12 col-12">
                  <Card
                    size={"large"}
                    heading={details.heading}
                    para={details.para}
                    icon={details.icon}
                    backgroundColor={"#fff"}
                    img={arrow}
                    address={details.address}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndustrySection;
