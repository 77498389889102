import React from "react";
import { Link } from "react-router-dom";
import product1 from "../../images/product1.png";
import product2 from "../../images/product2.png";

const ProductSection = () => {
  return (
    <div className="products-section" data-aos="fade-up">
      <div className="container">
        <h2 className="sub-heading text-center">
          Explore Our <span className="yellow">Products</span>
        </h2>
        <div className="row align-items-center justify-content-center">
          <div className="col-md-7">
            <div className="row mt-4" style={{gap:"20px"}}>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card">
                  <img src={product1} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card">
                  <img src={product2} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <Link className="btn btn-black" to="/product">
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
