import React from "react";
import Card from "../../comman/card/Card";
import { Link } from "react-router-dom";

const WeSection = ({ cardDetails }) => {
  return (
    <div>
      <section className="we-section mb-0" data-aos="fade-up">
        <div className="container">
          <div className="row justify-content-center text-center">
            {cardDetails
              ? cardDetails.map((details, i) => {
                  return (
                    <div key={i} className="col-lg-12 col-md-12 col-12">
                      <Card
                        size="small"
                        heading={details.heading}
                        para={details.para}
                        backgroundColor={details.color}
                        nohover={details.nohover}
                      />
                    </div>
                  );
                })
              : null}
          </div>

          <div className="text-center mt-4">
            <Link className="btn btn-black" to="/portfolio">
              See all Projects
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WeSection;
