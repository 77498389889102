import React from "react";
import PageHead from "../pagesHead/PageHead";
import FormSection from "../form/FormSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const Contact = () => {
  return (
    <div>
      <PageHead pageTitle={"Contact Us"} />
      <FormSection />
      <SubcribeSection />
    </div>
  );
};

export default Contact;
