import React from "react";
import PageHead from "../pagesHead/PageHead";
import talent from "../../images/Talent-On-demand.png";
import remote from "../../images/remote-developer.png";

import InnerPageSection from "../inner-page-section/InnerPageSection";
import ProductSection from "../product-section/ProductSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const imageDetails = [
  {
    heading: `Compliance GPT`,
    para: `An LLM-powered solution aimed at establishing a comprehensive knowledge base encompassing all compliance intelligence within an enterprise, with the goal of providing easy accessibility to both internal and external customers and stakeholders through a user-friendly chat-based interface.`,
    image: talent,
    alignment: "right",
  },
  {
    heading: `Early Warning System`,
    para: `An artificial intelligence-driven solution designed to oversee and notify about potentially fraudulent transactions within a banking system.`,
    image: remote,
    alignment: "right",
  },
];

const Solution = () => {
  return (
    <div>
      <div>
        <PageHead pageTitle={"Solutions"} />
      </div>
      <div>
        <InnerPageSection
          preYellowHeading={`Two`}
          yellowHeading={`ADVANCED`}
          postYellowHeading={`Solutions to make things more possible`}
          para={`Accelerate  your business with GenAI powered
          solutions designed to grow with your business needs`}
          imagesDetails={imageDetails}
        />
      </div>

     <div>
     <ProductSection />
     </div>

      <div>
        <SubcribeSection />
      </div>
    </div>
  );
};

export default Solution;
