import React from "react";
import styled from "styled-components";
import dot from "../../images/orange-dot.png";

const Badges = ({ icon, color, backgroundColor, width, height }) => {
  const Badge = styled.div`
    background-color: ${backgroundColor ? backgroundColor : "#ffb600"};
    border-radius: 20px;
    width: ${width ? width : "65px"};
    height: ${height ? height : "65px"};
    text-align: center;
    padding: 16px;
    color: ${color};
  `;
  const BadgeIcon = styled.img`
    max-width: 100%;
    height: auto;
  `;
  return (
    <div>
      {icon ? (
        <Badge>
          <BadgeIcon src={icon} alt={""} />
        </Badge>
      ) : (
        <BadgeIcon src={dot} />
      )}
    </div>
  );
};

export default Badges;
