// import React from "react";
// import SliderBar from "../../comman/slider/SliderBar";

// import techImages1 from "../../images/image 2.png";
// import techImages2 from "../../images/image 3.png";
// import techImages3 from "../../images/image 4.png";
// import techImages4 from "../../images/image 5.png";
// import techImages5 from "../../images/image 6.png";
// import techImages6 from "../../images/image 7.png";

// const techImages = [
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
//   { imageAddres: techImages1, imageAlt: "Flutter" },
//   { imageAddres: techImages2, imageAlt: "Docker" },
//   { imageAddres: techImages3, imageAlt: "Spring Boot" },
//   { imageAddres: techImages4, imageAlt: "Angular" },
//   { imageAddres: techImages5, imageAlt: ".Net" },
//   { imageAddres: techImages6, imageAlt: "Selenium" },
// ];

// const TechSlider = ({ preYellowHeadin, yellowHeading, postYellowHeading }) => {
//   return (
//     <div>
//       {preYellowHeadin ? (
//         <h2 class="sub-heading text-center">
//           {preYellowHeadin} <span class="yellow">{yellowHeading}</span>
//           <br /> {postYellowHeading}
//         </h2>
//       ) : null}
//       <section
//         className="container tech-Slider slider"
//         // data-aos="fade-up"
//       >
//         <div className="slide-track d-flex justify-content-between align-items-center  animation-container">
//           {techImages.map((item, i) => {
//             return (
//               <div id="techSlider" className="animate-div slide">
//                 <SliderBar
//                   key={i}
//                   images={item.imageAddres}
//                   imageAlt={item.imageAlt}
//                 />
//               </div>
//             );
//           })}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default TechSlider;



import React from "react";
import SliderBar from "../../comman/slider/SliderBar";

import techImages1 from "../../images/image 2.png";
import techImages2 from "../../images/image 3.png";
import techImages3 from "../../images/image 4.png";
import techImages4 from "../../images/image 5.png";
import techImages5 from "../../images/image 6.png";
import techImages6 from "../../images/image 7.png";
import techImages7 from "../../images/react-1-logo-black-and-white (1) (3).jpg";
import techImages8 from "../../images/next1 (1).jpg";

const generateTechImages = (count) => {
const techImages = [
  { imageAddres: techImages1, imageAlt: "Flutter" },
  { imageAddres: techImages2, imageAlt: "Docker" },
  { imageAddres: techImages3, imageAlt: "Spring Boot" },
  { imageAddres: techImages4, imageAlt: "Angular" },
  { imageAddres: techImages5, imageAlt: ".Net" },
  { imageAddres: techImages6, imageAlt: "Selenium" },
  { imageAddres: techImages7, imageAlt: "React Js" },
  { imageAddres: techImages8, imageAlt: "Next Js" },
];
const recursiveTechImages = [];
for (let i = 0; i < count; i++) {
  recursiveTechImages.push(...techImages);
}

return recursiveTechImages;
};

const TechSlider = ({ preYellowHeadin, yellowHeading, postYellowHeading }) => {
  const techImages = generateTechImages(12);
  return (
    <div>
      {preYellowHeadin ? (
        <h2 class="sub-heading text-center">
          {preYellowHeadin} <span class="yellow">{yellowHeading}</span>
          <br /> {postYellowHeading}
        </h2>
      ) : null}
      <section
        className="container tech-Slider slider"
        // data-aos="fade-up"
      >
        <div className="slide-track d-flex justify-content-between align-items-center  animation-container">
          {techImages.map((item, i) => {
            return (
              <div id="techSlider" className="animate-div slide">
                <SliderBar
                  key={i}
                  images={item.imageAddres}
                  imageAlt={item.imageAlt}
                />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default TechSlider;
