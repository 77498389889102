import React from "react";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import PageHead from "../pagesHead/PageHead";
import image from "../../images/cloud-main.png";
import cloudImage1 from "../../images/cloud-design.png";
import cloudImage2 from "../../images/cloud-migration.png";
import cloudImage3 from "../../images/devops.png";

import InnerPageSection from "../inner-page-section/InnerPageSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const imageDetails = [
  {
    heading: `Intelligent Test
    Automation`,
    para: `Test automation reduces test cycle time by 60 to 70%. Adopt autonomous
    testing using AI/ML and drive transformation by making testing the
    fastest cog in the DevOps chain. Our Test Automation offering applies
    a high-level of automation to increase the speed, efficiency and
    accuracy of the end-to-end quality validation process, from early
    requirements gathering to production. Just improve quality and save
    cost using our Test automation platform`,
    image: cloudImage1,
    alignment: "right",
  },
  {
    heading: `Managed Testing Services`,
    para: `We take end to end responsibility for Quality Engineering right from
    defining test strategy to test planning, test execution, test
    management, defect management, reporting, and optimization to make the
    entire SDLC process seamless and smooth. Our dedicated team of
    experienced QA Engineers  help you achieve excellence in every aspect
    of your software development and release process, including: Quality
    Engineering advisory, Functional testing, Non-functional testing and
    Test automation.`,
    image: cloudImage2,
    alignment: "right",
  },
  {
    heading: `Performance Testing`,
    para: `In today's digital landscape, it's crucial that a digital product can
    handle heavy usage without faltering, regardless of the company's
    size. The Performance Testing and Engineering practice at Areteminds
    assists clients in identifying and addressing bottlenecks encountered
    in software performance testing challenges. By providing advanced
    services, it enables clients to achieve superior levels of
    responsiveness, reliability, availability, scalability, and resilience
    for their mobile or web applications.`,
    image: cloudImage3,
    alignment: "right",
  },
];

const Testing = () => {
  return (
    <div>
      <PageHead pageTitle={`Testing`} />
      <WelcomeSection
        preYellowHeading={`Ensures Enterprise
        digital transformation With`}
        yellowHeading={`Holistic Testing`}
        postYellowHeading={`Solutions`}
        para={`The Next Gen Managed QA Services  is a proactive assurance model that
        discovers problems before they affect your business. Achieve quality
        at scale and deliver enhanced customer experiences with advanced
        automation-led, AI/ML-backed autonomous testing. Areteminds helps
        customers mature and scale quality engineering faster by putting our
        expertise and experience in these three areas to work for you.`}
        sectionImage={image}
        nobutton={true}
      />
      <InnerPageSection
        preYellowHeading={`Explore Our`}
        yellowHeading={`TESTING`}
        postYellowHeading={`Services`}
        para={`We offer the complete range of Quality Engineering services for a
        broad array of client needs—across digital, custom, packaged and
        emerging tech applications.
        `}
        imagesDetails={imageDetails}
      />
      <SubcribeSection />
    </div>
  );
};

export default Testing;
