import React from "react";
import PageHead from "../pagesHead/PageHead";
import digitalImage from "../../images/digital-service-main.png";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import InnerPageSection from "../inner-page-section/InnerPageSection";
import webImage from "../../images/web-application.png";
import mobImage from "../../images/mob-application.png";
import ecommerce from "../../images/digital-commerce.png";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const imageDetails = [
  {
    heading: `Web Applications`,
    para: `We understand web channels intimately. Automate, innovate, grow with digital services that power a new style of IT. Our expertise in JavaScript frameworks like Angular, React, Node JS among others are essential to create highly engaging solutions. We follow agile methodologies to endlessly and iteratively build web applications for you. We build scalable web applications for all OS, devices, and platforms, along with the best app development software.`,
    image: webImage,
    alignment: `right`,
  },
  {
    heading: `Mobile Applications`,
    para: `In digital world business users consider the ability to interact with customers, employees, assets, products, and other businesses in real time, anytime, and from any location a basic requisite. They expect to pay as they say, virtually be there, and essentially get all tasks done in micro moments. Mobile Centric Digital Transformation has become the defining enterprise mobility catalyst. Our Mobility services enable business houses wide mobile-must approach that enhances customer experiences across all touchpoints.`,
    image: mobImage,
    alignment: `right`,
  },
  {
    heading: `Digital Commerce`,
    para: `People buy and sell in totally new ways, with new habits, new expectations, and new preferences that were accelerated by the pandemic and the step change in circumstance in 2020.In the new consumer world, growth is just one click away. CPG companies can now take control of their supply chain, generate demand, manage customer experience, carry out digital marketing and manage overall digital commerce operations. We help brands reimagine commerce with a transformation system that balances complexity and simplicity to maximize the growth opportunity.`,
    image: ecommerce,
    alignment: `right`,
    noBackgroundBlock: false,
  },
];

const DigitalOffering = () => {
  return (
    <div>
      <div>
      <PageHead pageTitle={`Digital Service`} />
      </div>
      <div>
      <WelcomeSection
        preYellowHeading={`Our`}
        yellowHeading={`DIGITAL`}
        postYellowHeading={`offerings drive speed & innovation`}
        para={`We empower organizations to transform customer journeys and adopt insight led processes for creating seamless digital experiences across multiple channels. From Idea to reality we help you put digital at the core for sustainable and profitable business growth. Transform your values of operations and delivery by integrating digital technology into the areas of your business.`}
        postPara={`We use proven mobile-enabled, DevOps and microservices-oriented architecture coupled with agile development to assist organizations in achieving rapid return on investment and superior engagement.`}
        sectionImage={digitalImage}
        nobutton={true}
      />
      </div>
      <div>
      <InnerPageSection
        preYellowHeading={`Explore Our `}
        yellowHeading={`DIGITAL`}
        postYellowHeading={`Services`}
        para={`Harness the power of technology. Driving growth and competitiveness in the modern digital age.`}
        imagesDetails={imageDetails}
      />
      </div>
      <div>
      <SubcribeSection />
    </div>
    </div>
  );
};

export default DigitalOffering;
