import React from "react";
import Card from "../../comman/card/Card";

const PartnerProduct = ({
  preYellowHeading,
  yellowHeading,
  postYellowHeading,
  para,
  cardDetails,
}) => {
  return (
    <div>
      <section className="partner-products" data-aos="fade-up">
        <div className="container">
          <h2 className="sub-heading text-center">
            {preYellowHeading ? preYellowHeading : null}{" "}
            <span className="yellow">
              {yellowHeading ? yellowHeading : null}
            </span>
            {postYellowHeading ? postYellowHeading : null}
          </h2>
          <p className="sub-heading-p mx-auto text-center">
            {para ? para : null}
          </p>

          <div
            className="row align-items-center mt-lg-5 mt-2"
            data-aos="fade-right"
          >
            {cardDetails
              ? cardDetails.map((details, i) => {
                  return (
                    <div key={i} className="col-md-6 col-lg-6">
                      <Card
                        heading={details.heading ? details.heading : null}
                        wbox={true}
                        para={details.para ? details.para : null}
                        btnLabel={details.btnLabel ? details.btnLabel : null}
                        btnLabelAddress={
                          details.btnLabelAddress
                            ? details.btnLabelAddress
                            : null
                        }
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartnerProduct;
