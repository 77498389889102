import React from "react";
import BannerInner from "../BannerInner/BannerInner";
import TechSlider from "../techSlider/TechSlider";
import ServiceSection from "../service-section/ServiceSection";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import IndustrySection from "../industry-section/IndustrySection";
import ApproachSection from "../approach-section/ApproachSection";
import BrandSlider from "../brand-slider/BrandSlider";
import Testimonal from "../testimonal/Testimonal";
import SubcribeSection from "../subscribe-section/SubcribeSection";
import e1 from "../../images/e1.png";
import e2 from "../../images/e2.png";
import e3 from "../../images/e3.png";
import websiteHosting from "../../images/Homepage2.png";
import icon1 from "../../images/o1.png";
import icon2 from "../../images/o2.png";
import icon3 from "../../images/o3.png";
import icon4 from "../../images/o4.png";

const details = [
  {
    icon: e1,
    heading: "Reliable Tech Partner",
    para: `We care about our
    clients, about their success, and about the partnership we aim to
    build.`,
  },
  {
    icon: e2,
    heading: "Deep Expertise",
    para: `With Our Deep Technical and Domain
    expertise, We deliver The mission critical IT services that move the
    world.`,
  },
  {
    icon: e3,
    heading: "Agile Teams",
    para: `Deliver working solutions frequently, from a
    couple of weeks to a couple of months, with a preference to the
    shorter timescale.`,
  },
];
const cardDetails = [
  {
    icon: icon1,
    heading: "Digital offering",
    para: `We collaborate closely with you to establish a strong Digital Backbone, implement Intelligent Operations, and create an intelligent Connected Experience for your customers.`,
    address: "",
    backgroundColor: "#fff8ee",
    address: "/digitaloffering",
  },
  {
    icon: icon2,
    heading: "Data & AI",
    para: `Utilize the capabilities of data and AI to facilitate faster, predictive, and proactive decision-making, ensuring efficient decoding and analysis of data patterns.`,
    address: "",
    backgroundColor: "#fff8ee",
    address: "/cloud",
  },
  {
    icon: icon3,
    heading: "Testing",
    para: `Our offerings include testing digital solutions for functionality and performance across all platforms, with extensive consultation to meet business objectives.`,
    address: "",
    backgroundColor: "#fff8ee",
    address: "/testing",
  },
  {
    icon: icon4,
    heading: "Staff Augmentation",
    para: `We offer testing for digital solutions, both functional and non-functional, across all platforms, along with extensive Quality Assurance consultation services.`,
    address: "",
    backgroundColor: "#fff8ee",
    address: "/staffagumentation",
  },
];

const Home = () => {
  return (
    <div>
      <BannerInner />

      <TechSlider />
      <div data-aos="fade-up">
        <ServiceSection
          preYellowHeading={`Our excellent`}
          yellowHeading={`OFFERINGS`}
          postYellowHeading={`For you`}
          para={`Rise above any challenge with access
          to world-class services, solutions and talents.`}
          cardDetails={cardDetails}
          classNamegrid={`col-lg-3 col-md-6 col-sm-6 col-6`}
        />
      </div>
      {/* data-aos="fade-up" */}
      <div data-aos="fade-up">
        <WelcomeSection
          preYellowHeading={`We deliver`}
          yellowHeading={`EXCELLENCE`}
          postYellowHeading={`, every time`}
          para={`We
          stand for excellence in delivery, quality and innovation. Each member
          of our team takes responsibility for creating a culture that enables
          exceptional outcomes.`}
          badgesDetails={details}
          sectionImage={websiteHosting}
        />
      </div>

      <IndustrySection />
      <div data-aos="fade-up">
        <ApproachSection />
      </div>
      <BrandSlider />
      <Testimonal />
      <SubcribeSection data-aos="fade-up" />
    </div>
  );
};

export default Home;
