import React from "react";
import BadgesDetails from "../../comman/badgesDetails/BadgesDetails";

const Advantages = ({
  preYellowHeading,
  yellowHeading,
  postYellowHeading,
  badgesDetails,
}) => {
  return (
    <div>
      <section className="our-advantages-section" data-aos="fade-up">
        <div className="container">
          <h2 className="sub-heading text-center">
            {preYellowHeading ? preYellowHeading : null}{" "}
            <span className="yellow">
              {yellowHeading ? yellowHeading : null}
            </span>
            {" "+postYellowHeading ? postYellowHeading : null}
          </h2>

          <div className="row">
            {badgesDetails.map((detail, i) => {
              return (
                <div key={i} className="col-lg-6 col-md-12 col-12">
                  <div className="d-flex box">
                    <div className="order-1">
                      <figure>
                        <img src={detail.icon} className="img-fluid" alt="" />
                      </figure>
                    </div>
                    <div className="order-2">
                      <h2>{detail.heading}</h2>
                      <p>{detail.para}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Advantages;
