import React from "react";
import { Link } from "react-router-dom";

const SubcribeSection = () => {
  return (
    <div>
      <section className= "subscribe-section">
        <div className= "container">
          <div
            className= "row align-items-center justify-content-center"
            data-aos="fade-up"
          >
            <div className= "col-md-10">
              <h2 className= "text-center">
                Speak to an <span>AreteMindsian</span>. Find out
                <br />
                what we can do for you.
              </h2>
              <p className= "text-left">
                Whether you’re searching for a new IT partner or starting a new
                business, we would love to hear from you. Contact us now. We’ll
                answer your questions and provide free consultation.
              </p>
              <div className= "text-center">
                <Link className= "btn btn-black" to="/contactus">
                  Start a Project
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubcribeSection;
