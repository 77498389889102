import React from "react";
import styled from "styled-components";

const Frame = styled.div`
  border-radius: 30px;
  border: 5px solid var(--color-1);
   height: auto;
`;

// const Fram =()

const ImageFrame = ({ image }) => {
  return (
    <div>
      {/* <Frame> */}
      <img src={image} alt="" />
      {/* </Frame> */}
    </div>
  );
};

export default ImageFrame;
