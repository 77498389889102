import React from "react";
import location from "../../images/location.png";
import mail from "../../images/mail.png";
import call from "../../images/call.png";

const FormSection = () => {
  return (
    <div>
      <section class="contact-block container">
        <div class="row mx-0 align-items-center">
          <div class="col-lg-6 col-12">
            <h2 class="sub-heading">
              Get In <span class="yellow">Touch</span>
            </h2>

            <p class="mb-4">
              To get in touch with Areteminds team simply fill out <br />
              the contact form below.
            </p>

            <p class="d-lg-flex align-items-lg-start">
              <img src={location} class="img-fluid" alt="" />
              <span class="ps-2">
                4th Cross, Ramanjaneya Layout
                <br />
                Marathahalli, Bangalore India
              </span>
            </p>
            <p>
              <img src={mail} class="img-fluid" alt="" />
              <span class="ps-2">sales@areteminds.com</span>
            </p>
            <p>
              <img src={call} class="img-fluid" alt="" />
              <span class="ps-2">+91 9663205304</span>
            </p>
          </div>
          <div class="col-lg-6 col-12">
            <form>
              <div class="row">
                <div class="col-12">
                  <label for="Name" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="Name"
                    name="Name"
                  />
                </div>
                <div class="col-12">
                  <label for="Email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="Email"
                    name="Email"
                  />
                </div>
                <div class="col-12">
                  <label for="Name" class="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="Subject"
                    name="Subject"
                  />
                </div>
                <div class="col-12">
                  <label for="PhoneNo" class="form-label">
                    Phone No
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="PhoneNo"
                    name="PhoneNo"
                  />
                </div>
                <div class="col-12">
                  <label for="message" class="form-label">
                    Message
                  </label>
                  <textarea
                    class="form-control"
                    id="message"
                    name="message"
                    rows="5"
                  ></textarea>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-dark w-100 ">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormSection;
