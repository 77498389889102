import React from "react";
import PageHead from "../pagesHead/PageHead";
import InnerPageSection from "../inner-page-section/InnerPageSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";
import image1 from "../../images/automation.jpg";
import image2 from "../../images/datainsight.jpg";
import image3 from "../../images/audit.jpg";
import image4 from "../../images/Experience.jpg";
import image5 from "../../images/conciliation.jpg";
import image6 from "../../images/Engineering.jpg";

const imageDetails = [
  {
    heading: `Automation`,
    para: `We help you:`,
    image: image1,
    alignment: `right`,
    noBackgroundBlock: false,
    noYellowBorder: true,
    badgesDetails: [
      {
        para: `To verify KYC and other legal documents instantly.
    `,
      },
      {
        para: `Automate KYC process to reduce costs and human error.
        `,
      },
      {
        para: `Automate lending journey process to improve customer experience , reduce processing costs and increase efficiency.
        `,
      },
    ],
  },
  {
    heading: `Data Insight`,
    para: `We help you to support every decision by harnessing the power of both internal and external data sources.`,
    image: image2,
    alignment: `left`,
    noBackgroundBlock: false,
    noYellowBorder: true,
    badgesDetails: [
      {
        para: `CXO core administration dashboard.

    `,
      },
      {
        para: `Data engineering and management.

        `,
      },
      {
        para: `Mining and Analysis of empirical data from claims history and electronic health records.

        `,
      },
    ],
  },
  {
    heading: `Audit & Compliance`,
    para: `We help you meet market shifts and regulatory changes by our compliance solution.`,
    image: image3,
    alignment: `right`,
    noBackgroundBlock: false,
    noYellowBorder: true,
    badgesDetails: [
      {
        para: `Organize your compliance management processes with a searchable file library.`,
      },
      {
        para: `Aautomated task management capabilities, and built-in compliance monitoring and analytics.`,
      },
      {
        para: `Track your compliance status in real time, and subscribe to our optional notification service for regulatory change alerts.`,
      },
    ],
  },
  {
    heading: `Experience`,
    para: `We help you:`,
    image: image4,
    alignment: `left`,
    noBackgroundBlock: false,
    noYellowBorder: true,
    badgesDetails: [
      {
        para: `To create memorable experiences at every touchpoint of the user journey.
        `,
      },
      {
        para: `Conversational AI to help patients access the right care.`,
      },
      {
        para: `Apps for Personalized Healthcare Experience & recommendations at 10x Speed.
        `,
      },
    ],
  },
  {
    heading: `Re-conciliation`,
    para: `Technology stack to enable financial workflows such as Reconciliation, Commission calculation, Payout creation and reporting.

    `,
    image: image5,
    alignment: `right`,
    noBackgroundBlock: false,
    noYellowBorder: true,
    badgesDetails: [
      {
        para: `Automate complex financial operations to easily manage revenue reconciliations, commission calculations and identify leakages.
        `,
      },
      {
        para: `Intuitive analytics to understand how much you owe, are owed and have earned. Eliminate write-offs and cash-loss.
        `,
      },
    ],
  },
  {
    heading: `Product Engineering
    `,
    para: `We help you meet market shifts and regulatory changes by delivering new features or product at 10X speed.`,
    image: image6,
    alignment: `left`,
    noBackgroundBlock: false,
    badgesDetails: [
      {
        para: `Application Modernization.`,
      },
      {
        para: `Cloud Migration, Operation & Optimization.
        `,
      },
      {
        para: `Managed DevSecOps.
        `,
      },
      {
        para: `Product Maintenance & Enhancement.
        
        `,
      },
    ],
  },
];

const Industry = () => {
  return (
    <div>
      <PageHead pageTitle={`Focus Industries`} />
      <InnerPageSection
        postYellowHeading={`What we do`}
        para={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.`}
        imagesDetails={imageDetails}
      />
      <SubcribeSection />
    </div>
  );
};

export default Industry;
