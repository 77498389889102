import React from "react";

const SliderBar = ({ images, imageAlt }) => {
  return (
    <div>
      <div className="item">
        <img src={images} className="img-fluid" />
        {imageAlt ? <p>{imageAlt}</p> : null}
      </div>
    </div>
  );
};

export default SliderBar;
