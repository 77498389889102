import React from "react";
import PageHead from "../pagesHead/PageHead";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const Team = () => {
  return (
    <div>
      <PageHead pageTitle={`Teams`} />
      <section className="team-section" data-aos="fade-up">
        <div className="container">
          <h2 className="sub-heading text-center">
            Meet Our <span className="yellow">Leadership</span> Preparing For
            Your Success
          </h2>

          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="team-box">
                <h2>Alka Singh</h2>
                <h5>Founder</h5>
                <p>
                  Alka Singh has great leadership qualities and is an expert in
                  IT consulting, design, product management, project management,
                  and development. Having worked for the US, Europe, Singapore
                  and Middle East clients, experienced in implementing large
                  scale technology projects. Having 11+ years of experience
                  non-negotiable for quality of work.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="team-box">
                <h2>Abhishek Verma</h2>
                <h5>Co-founder</h5>
                <p>
                  Abhishek is a core techie at heart. He is an expert in Data
                  Sciences, analytics and technical consulting. He has managed
                  product lines and has experience in managing large-scale
                  projects. He has 10+ years of experience technologies like
                  Python, Java, Springboot, AWS, Cloud & React.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="team-box">
                <h2>Abhishek Gupta</h2>
                <h5>Co-founder</h5>
                <p>
                  A distinguished career of 17+ years handling Key Accounts,
                  Business development, Human Resource Management, Delivery
                  Management & Software development.He has extensive experience
                  in architecting & building large scalable systems. He has a
                  degree from NIT- Trichy with mostly US, Canada and Indian
                  customers.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="team-box">
                <h2>Ashish Kumar</h2>
                <h5>Co-founder</h5>
                <p>
                  He has extensive experience in architecting & building large
                  scalable systems. technology enthusiast, with a keenness for
                  effective leadership. He loves to drive learning for
                  corporates and hardcore educationist in technology. He has
                  overall 16+ yrs of experience with an engineering degree in
                  computer science.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SubcribeSection />
    </div>
  );
};

export default Team;
