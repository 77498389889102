import React from "react";
import PageHead from "../pagesHead/PageHead";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import WeSection from "../we-section/WeSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";
import sectionImage from "../../images/project-5.png";

const cardDetails = [
  {
    heading: `The Challenge`,
    para: `The client had distributed teams across the globe and was rapidly expanding through acquisitions in additional locations. These locations were not integrated seamlessly into one infrastructure. This severely impacted the project implementation, development, and software quality.
`,
    color: `#fff`,
    nohover: true,
  },
  {
    heading: `The Solution`,
    para: `After carrying out a health check, Nagarro identified the need to change the overall company mindset, and thus began their DevOps journey. After a DevOps workshop and assessment, a technical excellence training and several working sessions in an agile approach, a foundation was laid down for continuous integration and delivery pipelines for various technologies. At the same time, an emergent adaptive culture was sparked to respond appropriately to the fast-changing market situations.

        `,
    color: `#fff`,
    nohover: true,
  },
  {
    heading: `The Outcome`,
    para: `Besides a homogeneous tool stack, streamlined processes and practices, the journey proved to be a successful one with 8 times faster deployments and 44 times faster lead time, and improved deployment frequency. There was rapid feedback for development teams and stakeholders, and a global quality strategy was established that serves as a blueprint for all locations. A learning organization emerged.

        `,
    color: `#fff`,
    nohover: true,
  },
];

const Logistic = () => {
  return (
    <div>
      <PageHead
        pageTitle={`Logistics Made Simpler and Easier With Technology`}
      />
      <WelcomeSection
        postYellowHeading={`Logistics Made Simpler and Easier With Technology
`}
        para={`Client is Redefining Logistics by integrating technology and
        services. We built technology platform that helps businesses
        to optimize the complete supply chain along with providing
        great customer experience and better profit margins.`}
        sectionImage={sectionImage}
        nobutton={true}
      />
      <WeSection cardDetails={cardDetails} />
      <SubcribeSection />
    </div>
  );
};

export default Logistic;
