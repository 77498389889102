import React from "react";
import PageHead from "../pagesHead/PageHead";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import sectionImage from "../../images/project-6.png";
import WeSection from "../we-section/WeSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const cardDetails = [
  {
    heading: `The Challenge
        `,
    para: `The client had distributed teams across the globe and was rapidly expanding through acquisitions in additional locations. These locations were not integrated seamlessly into one infrastructure. This severely impacted the project implementation, development, and software quality.

        `,
    color: `#fff`,
    nohover: true,
  },
  {
    heading: `The Solution
        `,
    para: `After carrying out a health check, Nagarro identified the need to change the overall company mindset, and thus began their DevOps journey. After a DevOps workshop and assessment, a technical excellence training and several working sessions in an agile approach, a foundation was laid down for continuous integration and delivery pipelines for various technologies. At the same time, an emergent adaptive culture was sparked to respond appropriately to the fast-changing market situations.

        `,
    color: `#fff`,
    nohover: true,
  },
  {
    heading: `The Outcome`,
    para: `Besides a homogeneous tool stack, streamlined processes and practices, the journey proved to be a successful one with 8 times faster deployments and 44 times faster lead time, and improved deployment frequency. There was rapid feedback for development teams and stakeholders, and a global quality strategy was established that serves as a blueprint for all locations. A learning organization emerged.

    `,
    color: `#fff`,
    nohover: true,
  },
];

const Donation = () => {
  return (
    <div>
      <PageHead pageTitle={`A Digital Donation Platform`} />
      <div data-aos="fade-up">
      <WelcomeSection
        postYellowHeading={`Wes Provide Useful Services`}
        sectionImage={sectionImage}
        para={` Developed a digital platform, which aims to connect both
        nonprofit organizations and donors, through understandable,
        clearly defined small items for causes. We redefined the
        experience of giving, both for people and organizations.`}
        
      />
      </div>
      <WeSection cardDetails={cardDetails} />
      <SubcribeSection />
    </div>
  );
};

export default Donation;
