import React from "react";
import PageHead from "../pagesHead/PageHead";
import WelcomeSection from "../welcome-sectiom/WelcomeSection";
import cloudImage from "../../images/Data_and_AI.png";
import cloudImage1 from "../../images/AI.png";
import cloudImage2 from "../../images/Analytics.png";
import cloudImage3 from "../../images/Data_Enng.png";
import InnerPageSection from "../inner-page-section/InnerPageSection";
import SubcribeSection from "../subscribe-section/SubcribeSection";

const imageDetails = [
  {
    heading: `Artificial Intelligence`,
    para: `At Areteminds, we specialize in empowering organizations to leverage AI and machine learning to solve their most difficult challenges. Our offerings include Predictive & Forecasting Models development Custom LLM GenAI models for enterprises and  Personalized Content generation using AI. Our team is made up of data scientists, data architects, business and domain specialists, visualization and design specialists, technologists, and application engineers. 
    `,
    image: cloudImage1,
    alignment: "right",
  },
  {
    heading: `Analytics`,
    para: `We help you become data-driven.  With our expertise and framework-driven methodology, we help identify key market trends by converting your data into meaningful insights enabling you to introduce new products and services, optimize costs and streamline processes. We enable you to anticipate market changes and adapt quickly by leveraging data to  gain a competitive advantage, improve customer experience, boost productivity, and drive intelligent decision-making.`,
    image: cloudImage2,
    alignment: "right",
  },
  {
    heading: `Data Engineering`,
    para: `Extracting insights from your data can get challenging if the data is not quality-driven and reliable. AreteMinds can help you organize, clean, and manage data to extract granular insights that capitalize on opportunities, mitigate business risks and amplify customer engagement. We drive end-to-end management of data pipelines, architecture, pilots, storage, and APIs to filter out critical datasets and concurrent insights that truly matter to your business. Our end-to-end offerings that help you define the data strategy roadmap, implement a modern cloud data stack, put together the governance and security frameworks, and manage the data quality using modern data mesh and operations principles.`,
    image: cloudImage3,
    alignment: "right",
  },
];

const Cloud = () => {
  return (
    <div>
      <PageHead pageTitle={`Data & AI`} />
      <WelcomeSection
        preYellowHeading={`Exceed customer expectations through`}
        yellowHeading={`DATA AND AI`}
        postYellowHeading={`empowered better, faster decision-making.`}
        para={`Our expert Data Engineering team at AreteMinds helps  organizations monetize and maximize the value of their data  by taking a curated approach. With our innovative offerings you will harness the power of data and AI to drive faster, predictive and proactive decisioning. Our offerings  include Advisory, Implementation and Operate services with a  focus on reducing cost of ownership, avoiding technical debt.`}
        sectionImage={cloudImage}
        nobutton={true}
      />
      <InnerPageSection
        preYellowHeading={`Explore our`}
        yellowHeading={`Data & AI`}
        postYellowHeading={`Services`}
        para={`An integrated offering for you to scale and future-proof Data-powered transformation`}
        imagesDetails={imageDetails}
      />
      <SubcribeSection />
    </div>
  );
};

export default Cloud;
